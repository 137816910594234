import React, { Fragment } from "react";

const InitialInformation = (props) => {

  // const handleChange = (value) => {
  //   console.log(value);
  //   props.dispatch({
  //     type: "documentType",
  //     value: value,
  //   });
  //   props.handleOnClickNext();
  // };

  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-between flex-grow">
        <h1 className="text-lg mb-2 font-bold">Introducción</h1>
        <div>
          <p>
          Bienvenido al portal de validación de identidad de Jamar, a continuación te pediremos fotos de tu documento de identificación y un video de tu rostro, por favor toma las fotos y el video en un lugar con buena iluminación.
          </p>
        </div>
        <input
          className="bg-positive text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
          type="button"
          value="Iniciar"
          onClick={() => { props.handleOnClickNext() }}
        />
      </div>
      {/* <div>
        <input
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
          type="button"
          value="Next"
          onClick={() => { props.handleOnClickNext() }}
        />
      </div> */}
    </Fragment>
  );
};

export default InitialInformation;