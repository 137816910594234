export const getParams = function (url) {
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

export const fetchRetry = function (
    url,
    options = {},
    f1,
    f2,
    f3,
    retries = 12,
    backoff = 15000
) {
    const retryCodes = [404, 408, 500, 502, 503, 504, 522, 524, 401, 202];
    return fetch(url, options)
        .then((res) => {
            if (res.ok && res.status != 202) {
                f1(false)
                f2(false);
                res.json().then((data) => f3(data));
                return
            }
            if (res.status === 401 || res.status === 404) {
                f1(true);
                f2(false);
                return;
            }
            console.log(retries);
            if (retries > 0 && retryCodes.includes(res.status)) {
                setTimeout(() => {
                    return fetchRetry(url, options, f1, f2, f3, retries - 1, backoff);
                }, backoff);
            } else {
                f1(true);
                f2(false);
                return;
            }
        })
        .catch((error) => error);
};


// Get Device Type
export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

// Detectar Webcam
export const detectWebcam = (callback) => {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then((devices) => {
        callback(devices.some(device => 'videoinput' === device.kind));
    })
};

export const getCameraPermission = () => {
    navigator.getUserMedia({ video: true }, e => {
        return e.active
    }, err => {
        if (/denied/i.test(err)) return false
    });
};

// EXAMPLE OF USE
detectWebcam((hasWebcam) => {
    console.log(hasWebcam)
})


export const closePopUp = (e) => {
    e.preventDefault()

    sendMessage('exitedSdk', { "data": "holita" })

}

export const sendMessage = (action, payload = {}) => {
    postMessage({ action, payload }, 1, '*');
}

export const postMessage = (message, id = 0, origin) => {
    try {
        const parent = window.opener || window.parent;
        parent.postMessage(JSON.stringify({
            ...message,
            action: `HOLA-${id}::${message.action}`,
        }), origin);
    } catch (e) {
        console.error("Error: couldn't postMessage to parent window.", e);
    }
}
