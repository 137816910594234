import React, { Fragment, useEffect, useState } from "react";
import LoadingImg from '../../assets/images/load_init.gif';
import CheckMark from '../../assets/images/check-mark.png';
import CancelImg from '../../assets/images/cancel.png';
import { sendMessage } from "./../../Utils";

const findTerm = (str, term) => {
  if (str.includes(term)) {
    return str;
  }
};

function ErrorList(props) {
  const errores = props.errores;
  const listItems = errores.map((err) => {
    switch (err.code) {
      case findTerm(err.code, 'identicalImages'):
        err.code = "Imágenes idénticas"
        break;
      case findTerm(err.code, 'badText'):
        err.code = "Mal texto en el documento"
        break;
      case findTerm(err.code, 'blurryText'):
        err.code = "Texto borroso"
        break;
      case findTerm(err.code, 'smallImageSize'):
        err.code = "Imagen demasiado pequeña"
        break;
      case findTerm(err.code, 'noFace'):
        err.code = "No hay ningún rostro en el documento"
        break;
      case findTerm(err.code, 'noDocument'):
        err.code = "No hay ningún documento en la foto"
        break;
      case findTerm(err.code, 'missingFields'):
        err.code = "Campos faltantes en el documento"
        break;
      case findTerm(err.code, 'internalError'):
        err.code = "Fallo interno del servidor"
        break;
      case findTerm(err.code, 'multipleFaces'):
        err.code = "Múltiples rostros capturados simultaneamente en el video"
        break;
      case findTerm(err.code, 'noFace'):
        err.code = "No detectamos ningún rostro en el video. Recuerde grabar el video-selfie sin tapabocas"
        break;
      default:
        break;
    }
    return (
      <li key={err.documentError}>
        {err.documentError} - {err.code}
      </li>
    )
  });
  return <ul>{listItems}</ul>;
}

const FinalStep = (props) => {
  const [video, setVideo] = useState();
  const [isLoading, setIsloading] = useState();
  const [fetchResult, setFetchResult] = useState([]);
  const [hadError, setHadError] = useState(false);

  const refreshPage = (e) => {
    e.preventDefault();
    console.log(window.location.href)
    window.location.replace(window.location.pathname + window.location.search + window.location.hash);
    // window.location.assign(window.location.href)
  };

  const closePage = (e) => {
    e.preventDefault();
    window.close();
  };

  useEffect(() => {
    setIsloading(true);
    var files = new FormData();
    setVideo([props.state.selfieVideo]);

    files.append("document1", props.state.frontDocument[0], "front.jpg");
    if (props.state.documentType !== 'passport') {
      files.append("document2", props.state.backDocument[0], "back.jpg");
    }
    if (props.canVideo) {
      files.append("video", props.state.selfieVideo[0], "video.mp4");
    }


    files.append("user_id", props.userId);
    files.append("contract_id", props.contractId);
    files.append("file_type", props.state.documentType);
    
    if (props.country === undefined) {
      files.append("country", "CO");
    } else {
      files.append("country", props.country);
    }

    const URL_VALIDATION =
      "https://becomedigital-api.azurewebsites.net/api/v1/identity";

    setTimeout(() => {
      fetch(URL_VALIDATION, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${props.token}`,
        }),
        body: files,
      }).then((response) => {
        response.json().then((data) => {
          setIsloading(false);
          setFetchResult(data);
        });
      }).catch((error) => {
        setHadError(true)
      });
    }, 1000);
  }, []);

  const handleFinishValidation = (e) => {
    e.preventDefault();
    sendMessage('userFinishedSdk', { "data": fetchResult })
  };

  return (
    <Fragment>
      {isLoading ?
        (
          <div className="w-full h-full flex flex-col align-center justify-between p-8">
            <div className="w-full h-full flex align-center justify-center">
              <img src={LoadingImg} alt="Loading..." className="object-contain w-1/2 h-1/2 lg:w-1/4 self-center" />
            </div>
            <p className="text-center w-full mt-4">Estamos procesando sus datos...</p>
          </div>
        ) :
        (<div className="w-full h-full flex flex-col align-center justify-between p-8 fade-in">
          <p className="text-center">Fin de la verificación</p>
          {fetchResult.message === "El recurso fue creado" &&
            (
              <>
                <img src={CheckMark} alt="Check Mark" className="w-24 h-24 self-center" />
                <input
                  className="bg-positive text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  value="Finalizar"
                  onClick={handleFinishValidation}
                />
              </>
            )}

          {fetchResult.message === "Ya existe un registro con el mismo ID para esta compañia" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center">Ya existe una validación de identidad para este usuario.</p>
              </>
            )}

          {fetchResult.message === "Hubo un problema con el envio de los archivos" &&
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <ul>
                  <ErrorList errores={fetchResult.error} />
                </ul>
                <p className="text-center">Hubo en error con el envío de sus documentos, por favor reintente la validación.</p>

                <input
                  className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  value="Reintentar"
                  onClick={refreshPage}
                />
              </>
            )}

          {hadError ?
            (
              <>
                <img src={CancelImg} alt="Cancel" className="w-24 h-24 self-center" />
                <p className="text-center">Hemos perdido la conexión contigo</p>
                <p className="text-center">Tenemos dificultades para comunicarnos con nuestros servidores</p>
                <input
                  className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  value="Volver a intentar"
                  onClick={refreshPage}
                />
              </>
            ) : null}
        </div>
        )}
    </Fragment>
  );
};

export default FinalStep;
