import React from "react";
import "./App.css";
import Form from "./components/Form";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const mainStyle = {
  position: 'fixed',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'scroll',
  // webkitBackdropFilter: 'blur(5px)',
  backdropFilter: 'blur(5px)',
  backgroundColor: ' rgba(33,35,45,.9)',
  // webkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}

const App = () => {
  return (
      <React.Fragment>
        <main style={window === window.parent ? null : mainStyle} className="App items-center flex justify-center min-h-full md:h-full">
          <Router>
            <Switch>
              <Route exact path="/" component={() => <Form />} />
            </Switch>
          </Router>
        </main>
      </React.Fragment>
  );
};

export default App;
