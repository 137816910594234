import React, { Fragment, useState } from "react";
import WebcamCapture from "../WebcamCapture";
import BackImg from '../../assets/images/back-document.png';

const BackDocument = (props) => {
  const [backImg, setBackImg] = useState();
  
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-between flex-grow fade-in">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="username"
        >
          Parte Posterior de tu documento.
        </label>
        <img src={BackImg} className="object-contain w-1/2 h-1/2 lg:w-1/4 self-center" />
        <WebcamCapture content="Documento posterior" handleOnClickNext={props.handleOnClickNext} setBackImg={setBackImg} dispatch={props.dispatch} />
      </div>
      <div>
        {props.backDocument ? (
          <input
            className="bg-positive text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
            type="button"
            value="Siguiente"
            onClick={() => {
              props.handleOnClickNext();
            }}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default BackDocument;
