import React, { Fragment, useState, useReducer, useEffect } from "react";
import Stepper from "./Stepper";
import InitialInformation from "./steps/InitialInformation";
import FrontDocument from "./steps/FrontDocument";
import BackDocument from "./steps/BackDocument";
import SelfieVideo from "./steps/SelfieVideo";
import FinalStep from "./steps/FinalStep";
import LogoJamar from '../assets/images/logoJamar.png';
import CloseSVG from "../assets/images/close.svg"
import { closePopUp, postMessage, sendMessage, getDeviceType } from "./../Utils";

const complexStateInitial = {
  frontDocument: null,
  backDocument: null,
  selfieVideo: null,
  contractId: null,
  userId: null,
  token: null,
  documentType: "national-id",
  // .. more states
};

const closeStyles = {
  maxWidth: '25px',
  position: 'absolute',
  alignSelf: 'flex-end',
  marginLeft: 'auto'
}

const closeStylesError = {
  maxWidth: '25px',
  position: 'absolute',
  alignSelf: 'flex-start'
}

function reducer(state, action) {
  switch (action.type) {
    case "frontDocument":
      return { ...state, frontDocument: action.value };
    case "backDocument":
      return { ...state, backDocument: action.value };
    case "selfieVideo":
      return { ...state, selfieVideo: action.value };
    case "documentType":
      return { ...state, documentType: action.value };
    case "userId":
      return { ...state, userId: action.value };
    case "token":
      return { ...state, token: action.value };
    case "contractId":
      return { ...state, contractId: action.value };

    // ... more states
    default:
      throw new Error();
  }
}

const StepperForm = (props) => {
  const [error, setError] = useState(false);
  const [state, dispatch] = useReducer(reducer, complexStateInitial);
  const [activeStep, setActiveStep] = useState(1);
  const [frontImageDoc, setFrontImageDoc] = useState([]);
  const [video, setVideo] = useState([]);
  const [deviceType, setDeviceType] = useState();
  const [openFromIframe, setOpenFromIframe] = useState();
  const steps = [
    { title: "Intro" },
    { title: "Documento Frontal" },
    { title: "Documento Posterior" },
    { title: "Video Selfie" },
    { title: "Final" },
  ];
  const videoIndex = steps
    .map(function (e) {
      return e.title;
    })
    .indexOf("Video Selfie");

  const backDocIndex = steps
    .map(function (e) {
      return e.title;
    })
    .indexOf("Documento Posterior");

  if (
    (props.canVideo === false || props.canVideo == undefined) &&
    videoIndex > -1
  ) {
    steps.splice(videoIndex, 1);
  }

  if (state.documentType === "passport" && backDocIndex > -1) {
    steps.splice(backDocIndex, 1);
    console.log(steps)
  }

  // const handleOnClickStepper = (step) => {
  //   setActiveStep(step);
  // };

  const handleOnClickNext = () => {
    let nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  // const handleOnClickBack = () => {
  //   let prevStep = activeStep - 1;
  //   setActiveStep(prevStep);
  // };

  // const handleSubmitAll = () => {
  //   console.log("FIN", state);
  // };

  useEffect(() => {
    const deviceType = getDeviceType();
    window === window.parent ? setOpenFromIframe(false) : setOpenFromIframe(true);
    setDeviceType(deviceType);
    console.log(props.userId);
  }, []);

  const closePopUp = (e) => {
    e.preventDefault()

    sendMessage('exitedSdk', { "data": "exited manually by user" })

  }

  const onChangeUseReducer = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  return (
    <Fragment>
      <div className="flex items-center justify-center w-full md:w-1/2 p-6 h-full max-h-full">
        <div className="min-w-full w-full h-full lg:w-1/2">
          <form className="bg-white shadow-md px-8 pt-6 pb-8 mb-4 rounded-lg shadow-xl h-full flex items-center justify-center">
            {!props.error ? (
            <div className="w-full h-full flex items-center justify-center flex-col justify-between">
              {openFromIframe ? <button onClick={closePopUp} style={closeStyles}> <img src={CloseSVG}></img></button> : null}
              <img src={LogoJamar} className="object-contain w-full mb-4 md:w-1/3 self-center" alt="Logo Jamar" />
              {activeStep === steps.map(e => e.title).indexOf("Intro") + 1 ? (
                <InitialInformation
                  currentStep={activeStep}
                  handleChange={onChangeUseReducer}
                  documentType={state.documentType}
                  dispatch={dispatch}
                  steps={steps}
                  handleOnClickNext={handleOnClickNext}
                />
              ) : null}
              {activeStep === steps.map(e => e.title).indexOf("Documento Frontal") + 1 ? (
                <FrontDocument
                  setFrontImageDoc={setFrontImageDoc}
                  currentStep={activeStep}
                  handleChange={onChangeUseReducer}
                  frontDocument={state.frontDocument}
                  steps={steps}
                  dispatch={dispatch}
                  handleOnClickNext={handleOnClickNext}
                />
              ) : null }
              { activeStep === steps.map(e => e.title).indexOf("Documento Posterior") + 1 ? (
                <BackDocument
                  currentStep={activeStep}
                  handleChange={onChangeUseReducer}
                  backDocument={state.backDocument}
                  steps={steps}
                  dispatch={dispatch}
                  handleOnClickNext={handleOnClickNext}
                />
              ) : null }

              {activeStep === steps.map(e => e.title).indexOf("Video Selfie") + 1 ? (
                <SelfieVideo
                  currentStep={activeStep}
                  handleChange={onChangeUseReducer}
                  selfieVideo={state.selfieVideo}
                  steps={steps}
                  dispatch={dispatch}
                  handleOnClickNext={handleOnClickNext}
                  setVideo={setVideo}
                />
              ) : null }
              {activeStep === steps.map(e => e.title).indexOf("Final") + 1 ? (
                <FinalStep
                  currentStep={activeStep}
                  video={video}
                  steps={steps}
                  state={state}
                  country={props.country}
                  // handleSubmitAll={handleSubmitAll}
                  frontImageDoc={frontImageDoc}
                  userId={props.userId}
                  contractId={props.contractId}
                  token={props.token}
                  canVideo={props.canVideo}
                />
              ) : null}

              <Stepper
                steps={steps}
                activeStep={activeStep}
                // onSelect={num => { handleOnClickStepper(num) }}
                showNumber={false}
              />
            </div>
            ) : (
                    <Fragment>{openFromIframe ? <button onClick={closePopUp} style={closeStylesError}> <img src={CloseSVG}></img></button> : null}Validación expirada.</Fragment>
                )}
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default StepperForm;
