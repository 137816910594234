import React, { Fragment, useState } from "react";
import WebcamStreamCapture from "../WebStreamCapture";
import Liveness from '../../assets/images/liveness.gif';

const SelfieVideo = (props) => {
  const [selfieVideo, setSelfieVideo] = useState();
  
  return (
    <Fragment>
      <div className="w-full flex flex-col align-center justify-between flex-grow fade-in">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="password"
        >
          Video de tu rostro haciendo 2 círculos con tu cabeza.
        </label>
        <img src={Liveness} className="object-contain w-full h-full lg:w-1/3 self-center" />
        <WebcamStreamCapture
          setVideo={props.setVideo}
          handleOnClickNext={props.handleOnClickNext}
          dispatch={props.dispatch}
          setSelfieVideo={setSelfieVideo}
        />
      </div>
      <div>
        {props.selfieVideo ? (
          <input
            className="bg-positive text-white font-bold py-2 px-4 mt-5 mb-5 rounded focus:outline-none focus:shadow-outline"
            type="button"
            value="Siguiente"
            onClick={() => {
              props.handleOnClickNext();
            }}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default SelfieVideo;
