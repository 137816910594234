import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  facingMode: "face",
};

const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(
    (e) => {
      e.preventDefault();
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);

      if (props.setFrontImg) {
        props.dispatch({
          type: "frontDocument",
          value: imageSrc,
        });
        console.log(typeof imageSrc);
      } else {
        props.dispatch({
          type: "backDocument",
          value: imageSrc,
        });
      }
    },
    [webcamRef, setImgSrc]
  );

  const retry = React.useCallback(
    (e) => {
      e.preventDefault();
      setImgSrc(null);
    },
    [setImgSrc]
  );

  useEffect(() => {
    var camera = document.getElementById("camera");

    camera.addEventListener("change", function (e) {
      var file = e.target.files[0];
      console.log(file);
      var urlFile = URL.createObjectURL(file);
      console.log(urlFile);
      // Do something with the image file.
      if (props.setFrontImg) {
        props.dispatch({
          type: "frontDocument",
          value: [file],
        });
        props.setFrontImageDoc([file]);
      } else {
        props.dispatch({
          type: "backDocument",
          value: [file],
        });
      }
      props.handleOnClickNext();
    });
  }, []);

  return (
    <div className="relative w-64 mt-4 mb-4 self-center">
      <label className="bg-positive text-white font-bold py-2 px-4 w-full inline-flex items-center rounded text-center">
        <span className="text-base leading-normal text-center flex-grow">
          {props.content ? props.content : "Toma una foto."}
        </span>
        <input
          type="file"
          accept="image/*"
          capture="camera"
          id="camera"
          className="cursor-pointer hidden"
        ></input>
      </label>
    </div>
  );
};

export default WebcamCapture;
